import React from 'react'
import { round, formatHours, toHr } from '../utils/Math'

class Day extends React.Component {
  constructor(props) {
    super(props)
    this.state = { valueIn: '', valueOut: '', dayTotal: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  sendData = () => {
    this.props.parentCallback(this.props.name, this.state.dayTotal)
  }

  handleChange(event) {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.getDayTotal()
    })
  }

  getDayTotal() {
    const dayIn = this.state.valueIn
    const dayOut = this.state.valueOut
    if (!(dayIn && dayOut)) {
      this.setState({ dayTotal: '' })
      return
    }
    var diff = toHr(dayOut) - toHr(dayIn)
    this.setState(
      {
        dayTotal: diff > 0 ? diff : diff + 24,
      },
      () => {
        this.sendData()
      }
    )
  }

  removeChild() {
    this.props.removeChildCallback(this.props.name)
  }

  render() {
    return (
      <div className="day">
        <div className="day-totals">
          <span className="day-totals--hours">
            {this.state.dayTotal ? formatHours(this.state.dayTotal) : '00:00'}
          </span>
          <span className="day-totals--decimals">
            {this.state.dayTotal
              ? round(this.state.dayTotal, 2) + 'hr'
              : '0 hr'}
          </span>
        </div>
        <div className="day-inputs">
          <input
            type="text"
            name="valueIn"
            placeholder="Time In (HH:MM)"
            value={this.state.valueIn}
            onChange={this.handleChange}
          ></input>
          <input
            type="text"
            name="valueOut"
            placeholder="Time Out (HH:MM)"
            value={this.state.valueOut}
            onChange={this.handleChange}
          ></input>
        </div>
        <div>
          <button
            type="submit"
            disabled
            style={{ display: 'none' }}
            aria-hidden="true"
          ></button>
          <button
            className="remove"
            onClick={(ev) => {
              ev.preventDefault()
              this.removeChild()
            }}
          >
            Remove
          </button>
        </div>
      </div>
    )
  }
}

export default Day
