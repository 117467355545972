import React from 'react'
import './App.scss'
import Form from './Form'
import Day from './Day'
import { formatHours, round } from '../utils/Math'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [{ id: 0, name: 'Day 1', time: 0 }],
      listNext: 1,
      totalTime: 0,
    }
  }

  callbackFunction = (childName, childDayTotal) => {
    const elementsIndex = this.state.list.findIndex(
      (element) => element.name === childName
    )
    let newArray = [...this.state.list]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      time: childDayTotal,
    }
    this.setState(
      {
        list: newArray,
      },
      () => {
        this.getTotal()
      }
    )
  }

  getTotal() {
    let sum = 0
    this.state.list.map((item) => {
      return item.time ? (sum += item.time) : sum
    })
    this.setState({
      totalTime: sum,
    })
  }

  removeChildCallback = (childName) => {
    const elements = this.state.list.find(
      (element) => element.name === childName
    )

    this.setState(
      {
        list: this.state.list.filter(function (day) {
          return day !== elements
        }),
      },
      () => {
        this.getTotal()
      }
    )
  }

  addChild() {
    let nextId = this.state.listNext
    this.setState({
      listNext: nextId + 1,
      list: this.state.list.concat([{ id: nextId, name: 'Day ' + nextId + 1 }]),
    })
  }

  render() {
    return (
      <div className="App">
        <div className="container">
          <Form>
            {this.state.list.map((item) => (
              <Day
                key={item.id}
                name={item.name}
                parentCallback={this.callbackFunction}
                removeChildCallback={this.removeChildCallback}
              />
            ))}
          </Form>
          <div className="stats">
            <div className="total">
              <span className="day-totals--hours">
                {this.state.totalTime
                  ? formatHours(this.state.totalTime)
                  : '00:00'}
              </span>
              <span className="day-totals--decimals">
                {this.state.totalTime
                  ? round(this.state.totalTime, 2) + ' hr'
                  : '0 hr'}
              </span>
            </div>
            <div>
              <button
                onClick={(ev) => {
                  ev.preventDefault()
                  this.addChild()
                }}
              >
                Add Another
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
