function twoDigits(digits) {
  return digits.toString().length < 2
    ? ('00' + digits.toString()).substr(-2)
    : digits
}

function round(number, precision) {
  return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
}

function formatHours(hours, decimal) {
  if (decimal) return hours.toFixed(2) + ''

  return (
    twoDigits(Math.floor(hours)) +
    ':' +
    twoDigits(round((hours - Math.floor(hours)) * 60, 2))
  )
}

function toHr(time) {
  var regex = /(\d+):?(\d*)\s*((a|p).?m.?)?\s*/
  var res = regex.exec(time)
  if (!res) return
  var hr = res[1]
  var mn = res[2]
  var ap = res[3]

  if (hr === undefined) return
  if (hr.length < 4) {
    if (+hr > 0 && +hr < 12 && ap && ap[0].toLowerCase() === 'p')
      hr = +hr + 12 + ''
    if (hr === 12 && ap && ap[0].toLowerCase() === 'a') hr = '00'
    hr = twoDigits(hr) + twoDigits(mn)
  }

  return +hr.substr(0, 2) + +hr.substr(2, 2) / 60
}

export default (twoDigits, round, formatHours, toHr)
export { twoDigits, round, formatHours, toHr }
