import React from 'react'

class Form extends React.Component {
  render() {
    const { children } = this.props
    return <form>{children}</form>
  }
}

export default Form
